<template>
  <div class="mb-12">
    <SubHeader :type="$t('navbar.integrations')" />
    <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col" v-if="$store.state.AdminIntegration.authorized">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-1 bg-gray-200 md:rounded-lg my-5 py-3 md:py-0">
        <div class="w-full px-4 py-2 md:py-5">
          <label class="label pt-0">
            <span class="label-text">Keyword</span>
          </label>
          <input type="text" :placeholder="$t('adminIntegrations.search')" class="dl-input bg-white" v-model="filterKeyword" v-debounce:700ms="search" />
        </div>
        <div class="px-4 self-center mt-6">
          <multiselect
            v-model="errors"
            :options="[
              { id: 1, type: 'Integraciones con errores' },
              { id: 0, type: 'Integraciones sin errores' }
            ]"
            label="type"
            trackBy="id"
            valueProp="id"
            searchable
            :placeholder="$t('adminIntegrations.placeholderError')"
          ></multiselect>
        </div>
        <div class="px-4 self-center mt-6">
          <multiselect
            v-model="is_active"
            :options="[
              { id: 1, type: 'Integraciones activas' },
              { id: 0, type: 'Integraciones inactivas' }
            ]"
            label="type"
            trackBy="id"
            valueProp="id"
            searchable
            :placeholder="$t('adminIntegrations.placeholderActive')"
          ></multiselect>
        </div>
        <button class="btn btn-error btn-xs w-max self-center mt-6" style="height: fit-content" v-if="filterKeyword || errors != null" @click="cleanFilter()">{{ $t("adminIntegrations.deleteFilter") }}</button>
      </div>
      <div class="w-full mb-20">
        <div class="mx-auto">
          <div class="w-full flex flex-col">
            <div v-if="$store.state.AdminIntegration.loading" class="mt-12 w-full flex items-center justify-center">
              <Loader :onlyLoader="true" />
            </div>
            <div class="py-2 align-middle inline-block min-w-full" v-if="!$store.state.AdminIntegration.loading">
              <PaginationSelect :current_page="$store.state.AdminIntegration.meta.current_page" :from="$store.state.AdminIntegration.meta.from" :last_page="$store.state.AdminIntegration.meta.last_page" :path="$store.state.AdminIntegration.meta.path" :per_page="$store.state.AdminIntegration.meta.per_page" :to="$store.state.AdminIntegration.meta.to" :total="$store.state.AdminIntegration.meta.total" @change-page="paginationChange" />
              <div class="pt-2 pb-40 align-middle inline-block min-w-full" v-if="integrations.length">
                <div class="overflow-visible border border-gray-200 sm:rounded-lg">
                  <table class="divide-y divide-gray-200 table-auto min-w-full">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">#{{ $t("adminIntegrations.id") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.state") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.step") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.project") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'channel.name' ? '-channel.name' : 'channel.name')">
                            <span>{{ $t("adminIntegrations.channel") }}</span>
                            <TableSortDesc v-if="sort == '-channel.name'" />
                            <TableSortAsc v-if="sort == 'channel.name'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left">{{ $t("adminIntegrations.integrations") }}</th>
                        <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left">{{ $t("adminIntegrations.created") }}</th>
                        <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left">{{ $t("adminIntegrations.updated") }}</th>
                        <th scope="col" class="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(integration, integrationIndex) in integrations" :key="integrationIndex" class="transition min-w-full" :class="{ 'hover:bg-gray-100': integration.integrations_detail == undefined, 'bg-white': integration.integrations_detail, 'cursor-pointer': integration.integrations_detail == undefined }" @click="showIntegrations(integrationIndex)">
                        <!-- Integraciones de los project connections -->
                        <td colspan="100%" class="px-5 w-full" v-if="integration.integrations_detail" :class="{ hidden: !integration.show_details }" :ref="'row_details_' + integrationIndex">
                          <div class="py-3">
                            <table class="divide-y divide-gray-200 table-auto min-w-full">
                              <thead class="bg-gray-50">
                                <tr>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.id") }}</th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.state") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.service") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.frequency") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.listID") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.groupId") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.groupName") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.syncAt") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.errors") }}</th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider"></th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider"></th>
                                  <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider"></th>
                                </tr>
                              </thead>
                              <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(detail, detailIndex) in integration.integrations_detail" :key="detail.id" class="transition hover:bg-gray-100">
                                  <td class="px-3">
                                    <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ detail.id }} </span>
                                  </td>
                                  <td class="px-3">
                                    <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!detail.is_active">{{ $t("adminIntegrations.inactive") }}</span>
                                    <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="detail.is_active">{{ $t("adminIntegrations.active") }}</span>
                                  </td>
                                  <td class="px-3">
                                    <div class="text-sm text-gray-700 font-semibold inline-block break-all">{{ detail.service_name }}</div>
                                  </td>
                                  <td class="px-3">
                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ detail.schedule_frequency }}</div>
                                  </td>
                                  <td class="px-3">
                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ detail.config_to?.list_id || "-" }}</div>
                                  </td>
                                  <td class="px-3">
                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ detail.config_to?.group_id || "-" }}</div>
                                  </td>
                                  <td class="px-3">
                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ detail.config_to?.group_name || "-" }}</div>
                                  </td>
                                  <td class="px-3 text-left text-sm text-gray-500">
                                    {{ $moment(detail.sync_at).fromNow(true) }}
                                    <br />
                                    <small class="text-xs font-semibold text-gray-600">
                                      {{ $moment(detail.sync_at).format("YYYY-MM-DD HH:mm") }}
                                    </small>
                                  </td>
                                  <td class="px-3">
                                    <span class="px-3 inline-flex text-xs leading-5 font-bold rounded-full bg-green-100 text-green-800" v-if="!detail.is_error">{{ $t("adminIntegrations.withOutErrors") }}</span>
                                    <span class="px-3 inline-flex text-xs leading-5 font-bold rounded-full bg-red-100 text-red-800" v-if="detail.is_error">{{ $t("adminIntegrations.withErrors") }}</span>
                                  </td>
                                  <td class="px-3 text-left text-sm text-gray-500">
                                    <TransitionFade>
                                      <Spinner v-if="detail.loading_verification" />
                                      <span v-else key="button" class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded text-white cursor-pointer bg-blue-500 hover:bg-blue-400" style="font-size: 80%" @click="verifyIntegration(detail.id, integrationIndex, detailIndex)" v-tooltip.top="'Verificar integración'"><i class="pi pi-refresh"></i></span>
                                    </TransitionFade>
                                  </td>
                                  <td class="px-3 text-left text-sm text-gray-500">
                                    <span class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded text-white cursor-pointer bg-green-500 hover:bg-green-400" style="font-size: 80%" @click="activeModalConfirmRun(detail)" v-tooltip.top="'Ejecutar integración'"><i class="pi pi-play"></i></span>
                                  </td>
                                  <td class="px-3 py-2">
                                    <Dropdown>
                                      <template v-slot:buttons>
                                        <a href="javascript:void(0);" @click="changeIntegrationIsActive(detail)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 group" :class="{ 'hover:bg-red-100 hover:text-red-800': detail.is_active == 1, 'hover:bg-green-100 hover:text-green-800': detail.is_active == 0 }" role="menuitem"> <i :class="detail.is_active == 1 ? 'pi pi-ban' : 'pi pi-check-circle'"></i>{{ detail.is_active == 1 ? $t("adminIntegrations.deactivate") : $t("adminIntegrations.activate") }} </a>
                                        <a href="javascript:void(0);" @click="openLogs(detail.id)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-800 group" role="menuitem">
                                          <!-- <DropdownOptionDelete /> -->
                                          <i class="pi pi-eye"></i>{{ $t("adminIntegrations.seeLogs") }}
                                        </a>
                                        <a href="javascript:void(0);" @click="openDataOutput(detail.id)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem"> <i class="pi pi-info-circle"></i> {{ $t("adminIntegrations.infoSent") }} </a>
                                        <a href="javascript:void(0);" @click="copyLink(detail.project_id)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem" v-if="detail.service_name == 'Products'"> <i class="pi pi-link"></i>{{ $t("adminIntegrations.copy") }} </a>
                                        <a href="javascript:void(0);" @click="viewConfig(detail)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem"> <i class="pi pi-eye"></i>{{ $t("adminIntegrations.seeConfig") }} </a>
                                        <a href="javascript:void(0);" @click="modalSync(detail)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem"> <i class="pi pi-refresh"></i>{{ $t("adminIntegrations.syncIntegration") }} </a>
                                        <a href="javascript:void(0);" @click="confirmDelete(integration, detail)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 group" :class="{ 'hover:bg-red-100 hover:text-red-800': integration.is_active == 1, 'hover:bg-green-100 hover:text-green-800': integration.is_active == 0 }" role="menuitem"> <i class="pi pi-trash"></i>{{ $t("adminIntegrations.deleteService") }} </a>
                                      </template>
                                    </Dropdown>
                                  </td>
                                  <!-- <td class="px-3 py-2">
                                                <span class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded  text-white cursor-pointer bg-red-600 hover:bg-red-500" style="font-size: 80%" @click="openLogs(detail.id)">Ver logs</span>
                                            </td>
                                            <td class="px-3 py-2">
                                                <span class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded  text-white cursor-pointer bg-blue-600 hover:bg-blue-500" style="font-size: 80%" @click="openDataOutput(detail.id)" v-if="detail.config_to?.group_name">Informacion enviada</span>
                                                <span class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded  text-white cursor-pointer bg-blue-600 hover:bg-blue-500" style="font-size: 80%" v-if="detail.service_name == 'Products'" @click="copyLink(detail.project_id)">Copiar link para productos</span>
                                            </td> -->
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                        <td class="px-5" v-if="integration.integrations_detail == undefined">
                          <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ integration.id }} </span>
                        </td>
                        <td class="px-5" v-if="integration.integrations_detail == undefined">
                          <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!integration.is_active">{{ $t("adminIntegrations.inactive") }}</span>
                          <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="integration.is_active">{{ $t("adminIntegrations.active") }}</span>
                        </td>
                        <td class="px-5" v-if="integration.integrations_detail == undefined">
                          <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 uppercase">{{ integration.state }}</span>
                        </td>
                        <td class="px-5" v-if="integration.integrations_detail == undefined">
                          <div class="text-sm text-gray-700 font-semibold inline-block">{{ integration.project.name }} ({{ integration.project.company }})</div>
                        </td>
                        <td class="px-5" v-if="integration.integrations_detail == undefined">
                          <div class="text-sm text-gray-700 inline-block">
                            <div class="px-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-600 text-white uppercase">
                              {{ integration.channel.name }}
                            </div>
                          </div>
                        </td>
                        <td class="px-5 text-center" v-if="integration.integrations_detail == undefined">
                          <div class="rounded-full h-4 w-4 inline-block" :class="{ 'bg-green-600': integration.integration_error, 'bg-red-600': !integration.integration_error }"></div>
                        </td>
                        <td class="px-5 text-left text-sm text-gray-500" v-if="integration.integrations_detail == undefined">
                          {{ $moment(integration.created_at).fromNow(true) }}
                          <br />
                          <small class="text-xs font-semibold text-gray-600">
                            {{ $moment(integration.created_at).format("YYYY-MM-DD HH:mm") }}
                          </small>
                        </td>
                        <td class="px-5 text-left text-sm text-gray-500" v-if="integration.integrations_detail == undefined">
                          {{ $moment(integration.updated_at).fromNow(true) }}
                          <br />
                          <small class="text-xs font-semibold text-gray-600">{{ $moment(integration.updated_at).format("YYYY-MM-DD HH:mm") }}</small>
                        </td>
                        <td class="pr-6 py-3 text-right" v-if="integration.integrations_detail == undefined" @click.stop="">
                          <Dropdown>
                            <template v-slot:buttons>
                              <a href="javascript:void(0);" @click="changeProjectConnectionIsActive(integration)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 group" :class="{ 'hover:bg-red-100 hover:text-red-800': integration.is_active == 1, 'hover:bg-green-100 hover:text-green-800': integration.is_active == 0 }" role="menuitem">
                                <span class="cursor-pointer flex gap-2 items-center w-full"> <i :class="integration.is_active == 1 ? 'pi pi-ban' : 'pi pi-check-circle'"></i>{{ integration.is_active == 1 ? $t("adminIntegrations.deactivate") : $t("adminIntegrations.activate") }} </span>
                              </a>
                              <a href="javascript:void(0);" @click="viewConfig(integration)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem"> <i class="pi pi-eye"></i>{{ $t("adminIntegrations.seeConfig") }} </a>
                              <a href="javascript:void(0);" @click="editIntegration(integration)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem"> <i class="pi pi-pencil"></i> {{ $t("adminIntegrations.editIntegration") }} </a>
                              <a href="javascript:void(0);" @click="confirmDelete(integration)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 group" :class="{ 'hover:bg-red-100 hover:text-red-800': integration.is_active == 1, 'hover:bg-green-100 hover:text-green-800': integration.is_active == 0 }" role="menuitem"> <i class="pi pi-trash"></i>{{ $t("adminIntegrations.deleteIntegration") }} </a>
                            </template>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <div class="flex flex-col items-center mt-20">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.notfound") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col items-center mt-20">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
        <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminIntegrations.notAuth") }}</span>
      </div>
    </div>
    <Dialog header="Sincronizar integración" v-model:visible="showModalSync" :style="{ width: '90vw' }" modal>
      <div class="grid grid-cols-2 gap-3">
        <div>
          <label for="dateInit" class="uppercase text-gray-500 text-xs">{{ $t("adminIntegrations.initDate") }}</label>
          <input type="datetime-local" :placeholder="$t('adminIntegrations.initDate')" class="input py-2 px-3 h-auto input-bordered text-gray-900 w-full" name="dateInit" v-model="syncData.dateInit" />
        </div>
        <div>
          <label for="dateInit" class="uppercase text-gray-500 text-xs">$t('navbar.endDate')</label>
          <input type="datetime-local" :placeholder="$t('adminIntegrations.endDate')" class="input py-2 px-3 h-auto input-bordered text-gray-900 w-full" name="dateInit" v-model="syncData.dateEnd" />
        </div>
      </div>
      <div class="grid grid-cols-1 mt-3">
        <button class="btn btn-primary btn-xs mt-2" @click="syncSend()">{{ $t("adminIntegrations.sync") }}</button>
      </div>
    </Dialog>
    <Dialog header="Configuraciones" v-model:visible="showModalConfigs" :style="{ width: '50vw' }" position="center" modal>
      <h4 class="text-xl font-semibold leading-tight text-gray-800 mb-3">{{ integrationSelect.loading_verification == undefined ? "Credentials" : "Config" }} From</h4>
      <json-viewer :value="integrationSelect.config_from" preview copyable class="md:ml-6 text-sm md:text-md text-gray-500 md:py-2 md:px-4">
        {{ itemInfo[item] }}
      </json-viewer>
      <hr class="h-1 w-full my-4 bg-gray-800 border-none" />
      <h4 class="text-xl font-semibold leading-tight text-gray-800 mb-3">{{ integrationSelect.loading_verification == undefined ? "Credentials" : "Config" }} To</h4>
      <json-viewer :value="integrationSelect.config_to" preview copyable class="text-sm md:text-md text-gray-500 md:py-2 md:px-4">
        {{ itemInfo[item] }}
      </json-viewer>
    </Dialog>
    <Dialog header="Editar credenciales" v-model:visible="showModalEditCredentials" :style="{ width: '50vw' }" position="right" modal>
      <div class="grid grid-cols-1" v-if="integrationSelect.channel">
        <div class="w-full my-2" v-for="(input, i) in integrationSelect.channel.fields" :key="i">
          <label :for="input.name" class="uppercase text-gray-500 text-xs">{{ input.name }}</label>
          <input :type="input.type" :placeholder="input.name" class="input py-2 px-3 h-auto input-bordered text-gray-900 w-full" :name="input.name" v-model="formEdit[input.name]" />
        </div>
        <button class="btn btn-primary btn-xs mt-2" @click="sendEditCredential()">Editar credenciales</button>
      </div>
    </Dialog>
    <Dialog header="Confirmar ejecución" v-model:visible="showModalConfirmRun" :style="{ width: '50vw' }" modal>
      <div class="grid grid-cols-2 gap-3">
        <button class="btn btn-error btn-xs mt-2" @click="showModalConfirmRun = false">{{ $t("adminIntegrations.cancel") }}</button>
        <button class="btn btn-primary btn-xs mt-2" @click="runIntegration()">{{ $t("adminIntegrations.execute") }}</button>
      </div>
    </Dialog>
    <Dialog header="¿Esta seguro que desea eliminar la integración?" v-model:visible="showModalDelete" :style="{ width: '50vw' }" modal>
      <div class="grid grid-cols-2 gap-3">
        <button class="btn btn-info btn-xs mt-2" @click="showModalDelete = false">{{ $t("adminIntegrations.cancel") }}</button>
        <button class="btn btn-error btn-xs mt-2" @click="deleteIntegration()">{{ $t("adminIntegrations.delete") }}</button>
      </div>
    </Dialog>
    <Dialog header="¿Esta seguro que desea eliminar el servicio de la integración?" v-model:visible="showModalDeleteService" :style="{ width: '50vw' }" modal>
      <div class="grid grid-cols-2 gap-3">
        <button class="btn btn-info btn-xs mt-2" @click="showModalDeleteService = false">{{ $t("adminIntegrations.cancel") }}</button>
        <button class="btn btn-error btn-xs mt-2" @click="deleteServiceIntegration()">{{ $t("adminIntegrations.delete") }}</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader"
import Spinner from "@/components/UI/Spinner"
import SubHeader from "@/components/SubHeader"
import Dropdown from "@/components/Dropdowns/Dropdown"
import PaginationSelect from "@/components/PaginationSelect"
import TransitionFade from "@/components/TransitionFade"
import axiosClient from "@/config/AxiosClient"
import Dialog from "primevue/dialog"
import Multiselect from "@vueform/multiselect"
import TableSortDesc from "@/components/Table/TableSortDesc"
import TableSortAsc from "@/components/Table/TableSortAsc"

export default {
  name: "Admin Integrations",
  components: {
    Loader,
    Spinner,
    SubHeader,
    Dropdown,
    PaginationSelect,
    TransitionFade,
    Dialog,
    Multiselect,
    TableSortDesc,
    TableSortAsc
  },
  computed: {
    getErrors() {
      return this.errors
    }
  },
  data() {
    return {
      filterKeyword: "",
      sort: "-channel.name",
      limit: 50,
      default: {
        sort: "-channel.name",
        limit: 50
      },
      integrations: [],
      errors: null,
      menuErrorsOpen: false,
      showModalConfigs: false,
      integrationSelect: {},
      showModalSync: false,
      syncData: {
        dateInit: null,
        dateEnd: null
      },
      showModalEditCredentials: false,
      formEdit: {},
      is_active: 1,
      showModalConfirmRun: false,
      showModalDelete: false,
      showModalDeleteService: false,
      serviceIntegrationSelect: {}
    }
  },
  watch: {
    errors() {
      this.$nextTick().then(() => {
        this.search()
      })
    },
    is_active() {
      this.$nextTick().then(() => {
        this.search()
      })
    }
  },
  methods: {
    sortAndRefresh(sort) {
      this.sort = sort
      this.search()
    },

    search: async function (config = {}) {
      let filter = {}
      if (this.filterKeyword) filter["filter[id|project.id|project.name|project.company|channel.name|state][like]"] = `%${this.filterKeyword}%`
      filter.page = config.page || 1
      filter.sort = this.sort || this.default.sort
      filter.limit = config.limit || this.default.limit
      filter.include = "project,channel"
      if (this.errors) filter.errors = this.errors
      if (this.is_active != null) filter["filter[is_active][=]"] = this.is_active
      await this.$store.dispatch("AdminIntegration/list", filter)
      let integrations = []
      this.$store.state.AdminIntegration.data.forEach((integration) => {
        integrations.push({
          ...integration,
          integration_error: integration.integrations.every((f) => f.is_error == false || f.is_active == false)
        })
        integrations.push({
          integrations_detail: integration.integrations.map((f) => {
            // FIXME: Uncaught (in promise) TypeError: Cannot read properties of null (reading 'id')

            return { id: f.id, service_id: f.service.id, service_name: f.service.name, channel: integration.channel.name, is_error: f.is_error, is_active: f.is_active, project_connection_id: f.project_connection_id, schedule_frequency: f.schedule_frequency, config_to: f.config_to, sync_at: f.sync_at, project_id: integration.project_id, loading_verification: false, config: { from: f.config_from, to: f.config_to } }
          }),
          show_details: false
        })
      })
      this.integrations = [...integrations]
    },
    showIntegrations(index) {
      this.integrations[index + 1].show_details = !this.integrations[index + 1].show_details
    },

    paginationChange(page) {
      this.search({ page })
    },

    async openLogs(integration_id) {
      window.open(`/administrator/integrations/logs/${integration_id}`, "_blank").focus()
    },

    cleanFilter() {
      this.filterKeyword = ""
      this.errors = null
      this.search()
    },
    async changeProjectConnectionIsActive(projectConnection) {
      await this.$store.dispatch("AdminIntegration/changeProjectConnection", { id: projectConnection.id, is_active: projectConnection.is_active == 1 ? 0 : 1 })
      await this.search()
    },
    async changeIntegrationIsActive(integration) {
      await this.$store.dispatch("AdminIntegration/changeIntegration", { id: integration.id, is_active: integration.is_active == 1 ? 0 : 1 })
      await this.search()
    },
    openDataOutput(integration_id) {
      window.open(`/data/output/${integration_id}`, "_blank").focus()
    },
    copyLink(project_id) {
      // Crea un campo de texto "oculto"
      var aux = document.createElement("input")

      // Asigna el contenido del elemento especificado al valor del campo
      let url = `https://setup.baymark.net/api/2.0/projects/${project_id}/content/2.0/xml`
      aux.setAttribute("value", url)

      // Añade el campo a la página
      document.body.appendChild(aux)

      // Selecciona el contenido del campo
      aux.select()

      // Copia el texto seleccionado
      document.execCommand("copy")

      // Elimina el campo de la página
      document.body.removeChild(aux)
      this.$toast.success("Se copio el link al portapapeles")
    },
    async verifyIntegration(integration_id, indexProjectConnection, indexIntegration) {
      this.integrations[indexProjectConnection].integrations_detail[indexIntegration].loading_verification = true

      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
      try {
        await axiosClient.get(`/api/2.0/admin/integration/${integration_id}/verify`, config)
        this.integrations[indexProjectConnection].integrations_detail[indexIntegration].is_error = 0
        this.$toast.success("La integracion corrio correctamente", { duration: 1000 })
      } catch (error) {
        this.integrations[indexProjectConnection].integrations_detail[indexIntegration].is_error = 1
        this.$toast.error("La integracion tuvo un error", { duration: 1000 })
      }

      this.integrations[indexProjectConnection].integrations_detail[indexIntegration].loading_verification = false
    },
    viewConfig(integration) {
      this.integrationSelect = integration
      this.showModalConfigs = true
    },
    modalSync(integration) {
      this.integrationSelect = integration
      this.showModalSync = true
    },
    async syncSend() {
      if (this.syncData.dateInit == null || this.syncData.dateInit == "") {
        this.$toast.error("Debe ingresar la fecha de inicio")
        return
      }
      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
      await axiosClient.post(
        `/api/2.0/admin/integration/${this.integrationSelect.id}/syncAt`,
        {
          dateInit: this.syncData.dateInit,
          dateEnd: this.syncData.dateEnd
        },
        config
      )
      this.$toast.success("Se esta ejecutando la integracion")
      this.syncData.dateInit = null
      this.syncData.dateEnd = null
      this.syncData.method = "bulk"
      this.showModalSync = false
    },
    editIntegration(projectConnection) {
      this.$store.commit("Integration/setprojectConnectionEdit", projectConnection)
      this.$router.push({
        name: "IntegrationEdit",
        params: {
          project_connection_id: projectConnection.id
        }
      })
    },
    async sendEditCredential() {
      await this.$store.dispatch("AdminIntegration/confirmConfig", { data: { config_from: this.formEdit }, id: this.integrationSelect.id })
      this.showModalEditCredentials = false
      await this.search()
    },
    activeModalConfirmRun(integration) {
      this.integrationSelect = integration
      this.showModalConfirmRun = true
    },
    async runIntegration() {
      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
      await axiosClient.get(`/api/2.0/admin/integration/${this.integrationSelect.id}/run`, config)
      this.$toast.success("La integracion se esta ejecutando", { duration: 1000 })
      this.showModalConfirmRun = false
    },
    confirmDelete(integration, serviceIntegration = undefined) {
      if (!serviceIntegration) {
        this.integrationSelect = integration
        this.showModalDelete = true
      } else {
        this.serviceIntegrationSelect = serviceIntegration
        this.showModalDeleteService = true
      }
    },
    async deleteIntegration() {
      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
      await axiosClient.delete(`/api/2.0/admin/project/connection/${this.integrationSelect.id}`, config)
      this.$toast.success("La integración se eliminó correctamente", { duration: 1000 })
      this.showModalDelete = false
      await this.search()
    },
    async deleteServiceIntegration() {
      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
      await axiosClient.delete(`/api/2.0/admin/integration/service/${this.serviceIntegrationSelect.id}`, config)
      this.$toast.success("El servicio de la integración se elimino correctamente", { duration: 1000 })
      this.showModalDeleteService = false
      await this.search()
    }
  },
  async mounted() {
    this.search()
  }
}
</script>
